<template>
  <div>
    <div class="row flex-column-reverse flex-lg-row bg-white p-4 shadow-sm">
      <div class="col-xl-8">
        <h4 class="font-heading text-dark font-weight-bold">
          {{ $t('sparkmembers.contents.views.lives.no-lives.title') }}
        </h4>
        <div class="mt-4">
          <div class="step-item">
            <div class="step-bullet">1</div>
            <div class="step-text">
              {{ $t('sparkmembers.contents.views.lives.no-lives.step-1') }}
            </div>
          </div>
          <div class="step-item">
            <div class="step-bullet">2</div>
            <div class="step-text">
              {{ $t('sparkmembers.contents.views.lives.no-lives.step-2') }}
            </div>
          </div>
        </div>
        <MButton
          v-if="canEdit"
          class="btn-student-view mt-4 d-none d-md-block"
          icon="plus-circle"
          :label="$t('sparkmembers.contents.views.lives.no-lives.btn-new-live')"
          variant="primary"
          @click="$router.push({ name: 'CreateLive', params: { module: module.course_modules } })"
        />
      </div>
      <div class="col-xl-4 mb-4 mb-lg-0">
        <img src="@/assets/images/SparkMembers/Contents/views/Lives/robot-calendar.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  components: {
    MButton,
  },
  props: {
    module: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.font-heading {
  font-size: 1.25rem;
}
.step-item {
  display: grid;
  grid-template-columns: 28px auto;
  column-gap: 8px;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }
}
.step-bullet {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ead3fe;
  border: 2px solid #35126e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #35126e;
}

.step-text {
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #35126e;
  display: flex;
}
</style>
